.nav-content ul{
    width: auto;
}

.history-event-section{
    border: 1px solid #E6E9EF;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 6px;
    height: auto;
}

span.event-story-header {
    display: block;
    font-family: 'Inter', sans-serif;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #073868;
  }
  
